<template>
  <div class="home">
    <v-app-bar color="indigo darken-1" dark clipped-left app class="no-print">
      <v-toolbar-title>{{ "実績" }}</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- TMPアバターチップ -->
      <v-chip
        class="ma-2"
        color="pink darken-1"
        text-color="white"
        @click="userTop"
      >
        <template>
          <v-avatar left>
            <v-icon>mdi-keyboard-backspace</v-icon>
          </v-avatar>
          戻る
        </template>
      </v-chip>
      <v-chip
        class="ma-2"
        color="lime darken-4"
        text-color="white"
        @click="logout"
      >
        <template>
          <v-avatar left>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          {{ $store.state.name }}
        </template>
      </v-chip>
    </v-app-bar>

    <v-main>
      <v-container>
        <div>
          <div class="mb-1">
            <p class="text-h5">勤務実績参照</p>
            <div class="d-flex">
              <DatePicker
                v-model="date"
                label="対象年月"
                type="month"
                outlined
                prepend-icon="mdi-calendar"
              />
              <Btn
                color="info"
                icon="mdi-table-search"
                class="ml-8 mt-2"
                @click="getData"
                >検索</Btn
              >
            </div>
          </div>
          <hr />
          <div class="mt-5">
            <vue-good-table
              v-if="rows.length"
              :columns="cols"
              :rows="rows"
              :sort-options="{
                enabled: false
              }"
              :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 30,
                perPageDropdown: [5, 10, 30],
                dropdownAllowAll: true,
                setCurrentPage: 1,
                nextLabel: '前',
                prevLabel: '次',
                rowsPerPageLabel: '1ページ表示件数',
                ofLabel: 'of',
                pageLabel: 'page',
                allLabel: 'All'
              }"
            >
            </vue-good-table>
            <p v-else>該当する実績が存在しません</p>
          </div>
        </div>
      </v-container>
    </v-main>
  </div>
</template>
<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
const DATE = new Date();
const Headers = [
  {
    value: "date",
    text: "日付"
  },
  {
    value: "name",
    text: "所属"
  },
  {
    value: "start",
    text: "出"
  },
  {
    value: "end",
    text: "退"
  }
];

export default {
  name: "UserRoster",
  mixins: [Common, Api, ShowDialogs],
  components: {
    VueGoodTable
  },
  computed: {
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  data() {
    return {
      date:
        DATE.getFullYear() +
        "-" +
        ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(DATE.getDate())).slice(-2),
      show: false,
      cols: [],
      rows: []
    };
  },
  methods: {
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 16);
      const targetdt = new Date(this.Year, this.Month, this.Day);

      if (targetdt == startmonth) {
        targetdt.setMonth(targetdt.getMonth() + 1);
      } else if (targetdt > startmonth && targetdt.getDate() < 16) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      } else if (targetdt < startmonth && targetdt.getDate() < 16) {
        targetdt.setMonth(targetdt.getMonth() - 1);
      }

      const targetMonth =
        targetdt.getFullYear() + "-" + ("00" + targetdt.getMonth()).slice(-2);

      return targetMonth;
    },
    async getData() {
      console.log("getData", this.$store.state.userincode);
      try {
        const targetMonth = await this.getTargetMonth();
        const param = {
          incode: this.$store.state.userincode,
          targetmonth: targetMonth
        };
        const encode = encodeURI(JSON.stringify(param));
        const result = await this.$get(
          this.Paths.attendance,
          "user-roster=" + encode
        );

        this.cols = result.headers.map(header => ({
          label: header.text,
          field: header.value
        }));

        this.rows = result.body;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    userTop() {
      console.log("userTop");
      this.$router.push({ name: "UserTop" });
    },
    async logout() {
      console.log(this.$store);
      if (await this.$confirm("ログアウトしますか？")) {
        this.$store.commit("logout");
        console.log(this.$store);
        this.$router.push("/login");
      }
    }
  },
  async created() {
    console.log("created", this.args);
    this.date = this.$getPmServiceDate();
    await this.getData();
    try {
      await this.getData();
    } catch (e) {
      console.log(e);
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>
<style>
.vgt-wrap__footer .footer__row-count__label,
.vgt-wrap__footer .footer__navigation,
.vgt-wrap__footer .footer__row-count__select,
.vgt-wrap__footer .footer__navigation__page-btn span {
  font-size: 14px;
}
</style>
